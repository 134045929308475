import * as actionTypes from './actionTypes';
import * as selectors from './selectors';
import backend from '../../backend';

export const error = error => ({
    type: actionTypes.ERROR,
    error
});

export const loading = () => ({
    type: actionTypes.LOADING
});

export const loaded = () => ({
    type: actionTypes.LOADED
});

export const languageCompleted = language => ({
    type: actionTypes.APP_LANGUAGE,
    language
});

export const language = language => (dispatch, getState) => {
    const currentLanguage = selectors.getLanguage(getState());

    if (currentLanguage) {
        if (currentLanguage !== language) {
            dispatch(languageCompleted(language));
        }
    } else {
        dispatch(languageCompleted(language));
    }
};

export const activeLanguages = activeLanguages => ({
    type: actionTypes.ACTIVE_LANGUAGES,
    activeLanguages
});

export const availableLanguages = availableLanguages => ({
    type: actionTypes.AVAILABLE_LANGUAGES,
    availableLanguages
});

const applicationDataCompleted = applicationData => ({

    type: actionTypes.APPLICATION_DATA,
    applicationData

});

export const applicationData = (onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(loading());
    backend.applicationDataService.findApplicationData(selectors.getLanguage(getState()),
        (applicationData) => {
            dispatch(applicationDataCompleted(applicationData));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(loaded());
        },
        (errors) => {
            dispatch(loaded());
            if (typeof onErrors === 'function') {

                onErrors(errors);

            }
        }
    );
}

export const locale = locale => ({
    type: actionTypes.APP_LOCALE,
    locale
});
