import React from 'react';
import jQuery from 'jquery';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as FilePond from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import * as userSelectors from '../../users/selectors';
import * as configurationParameterSelectors from '../../configurationParameter/selectors';
import { groupBy } from 'lodash';
import InternationalizationRender, { getInternationalization } from '../../app/components/InternationalizationRender';
import { Errors } from '../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import AddModifyGeometricElementConfirmation from './AddModifyGeometricElementConfirmation';
import { GeometricElementTypeAttributesFields, sortGeometricElementTypeAttributeTypeComparator } from '../../common/util';

const MANAGE_GEOMETRIC_ELEMENT_DESCRIPTION_FIELD = {

    mandatory: 'DESCRIPTION_MANDATORY_FIELD',
    notMandatory: 'DESCRIPTION_NOT_MANDATORY_FIELD'

}

const mapStateToProps = function (state) {

    return {
        locale: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        allCodes: selectors.getAllCodes(state),
        geometricElementType: selectors.getGeometricElementType(state),
        user: userSelectors.getUser(state),
        configurationParameters: configurationParameterSelectors.getTotalConfigurationParameters(state)
    }
}

class AddGeometricElement extends React.Component {

    toFileToInsertJSON = (fileItem) => {
        let fileJSON = {
            "size": fileItem.fileSize,
            "type": fileItem.fileType,
            "filename": fileItem.filename,
            "base64": fileItem.getFileEncodeBase64String(),
            "uuid": null
        }
        return fileJSON;
    }

    fromGeometricElementFileDtoToFileToInsertJSON = (geometricElementFileDto) => {
        let fileJSON = {
            size: geometricElementFileDto.size,
            type: geometricElementFileDto.contentType,
            filename: geometricElementFileDto.originalFilename,
            base64: null,
            uuid: geometricElementFileDto.uuid
        }
        return fileJSON;
    }

    constructor(props) {
        super(props);
        this.state = {
            modifyFeature: this.props.modifyFeature,
            geom: this.props.geom,
            files: [],
            backendErrors: null,
            formikErrors: null,
            confirmationModalShow: false,
            functionToExecute: null
        };

    }

    enableGeometricElementConfirmationContactDataAlert = false;
    privateGeometricElementConfirmationContactDataAlert = null;
    publicGeometricElementConfirmationContactDataAlert = null;

    manageDescriptionFieldValue = null;

    componentDidUpdate(prevProps) {
        if (this.props.modifyFeature !== this.state.modifyFeature) {
            this.setState({ modifyFeature: this.props.modifyFeature });
        }
        if (this.props.geom !== this.state.geom) {
            this.setState({ geom: this.props.geom });
        }
        if (this.state.formikErrors || this.state.backendErrors) {
            let container = jQuery('#addGeometricElementContainer');
            if (this.state.backendErrors) {
                container.scrollTop(0);
            } else {
                if (this.state.formikErrors) {
                    let field = jQuery('.alert-danger:visible:first').prev();
                    if (field.length)
                        jQuery(container).animate({
                            scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                        });
                }
            }
        }

        if (this.enableGeometricElementConfirmationContactDataAlert && this.privateGeometricElementConfirmationContactDataAlert) {
            jQuery("#geometricElementConfirmationAlert").html(this.privateGeometricElementConfirmationContactDataAlert);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.modifyFeature !== this.state.modifyFeature) {
            return true;
        }
        if (nextProps.geom !== this.state.geom) {
            return true;
        }
        if (nextProps.geometricElementType !== this.props.geometricElementType) {
            return true;
        }
        if (nextProps.locale !== this.props.locale) {
            return true;
        }
        if (
            (nextState.formikErrors !== this.state.formikErrors) ||
            (nextState.backendErrors !== this.state.backendErrors)
        ) {
            return true;
        }
        if (nextState.confirmationModalShow !== this.state.confirmationModalShow ||
            nextState.functionToExecute !== this.state.functionToExecute) {
            return true;
        }

        return false;
    }

    render() {

        if (this.props.configurationParameters) {

            this.enableGeometricElementConfirmationContactDataAlert = this.props.configurationParameters.filter(configurationParameter =>
                configurationParameter.code.indexOf("ENABLE_GEOMETRIC_ELEMENT_CONFIRMATION_CONTACT_DATA_ALERT") !== -1);

            if (this.enableGeometricElementConfirmationContactDataAlert.length > 0) {
                this.enableGeometricElementConfirmationContactDataAlert = (this.enableGeometricElementConfirmationContactDataAlert[0].value === 'true');
            } else {
                this.enableGeometricElementConfirmationContactDataAlert = false;
            }

            this.privateGeometricElementConfirmationContactDataAlert = this.props.configurationParameters.filter(configurationParameter =>
                configurationParameter.code.indexOf("PRIVATE_GEOMETRIC_ELEMENT_CONFIRMATION_CONTACT_DATA_ALERT") !== -1);

            if (this.privateGeometricElementConfirmationContactDataAlert.length > 0) {
                this.privateGeometricElementConfirmationContactDataAlert = this.privateGeometricElementConfirmationContactDataAlert[0].value;
            } else {
                this.privateGeometricElementConfirmationContactDataAlert = null;
            }

            this.publicGeometricElementConfirmationContactDataAlert = this.props.configurationParameters.filter(configurationParameter =>
                configurationParameter.code.indexOf("PUBLIC_GEOMETRIC_ELEMENT_CONFIRMATION_CONTACT_DATA_ALERT") !== -1);

            if (this.publicGeometricElementConfirmationContactDataAlert.length > 0) {
                this.publicGeometricElementConfirmationContactDataAlert = this.publicGeometricElementConfirmationContactDataAlert[0].value;
            } else {
                this.publicGeometricElementConfirmationContactDataAlert = null;
            }

            this.manageDescriptionFieldValue = this.props.configurationParameters.filter(configurationParameter =>
                configurationParameter.code.indexOf("MANAGE_GEOMETRIC_ELEMENT_DESCRIPTION_FIELD") !== -1);

            if (this.manageDescriptionFieldValue.length > 0) {

                this.manageDescriptionFieldValue = this.manageDescriptionFieldValue[0].value;

            }

        }

        jQuery(function () {
            //show tooltip
            jQuery('.helpTooltip').tooltip({
                html: true,
                placement: 'left',
                trigger: 'click',
                // Prevent placement flip
                fallbackPlacement: ['left'],
                boundary: 'window',
                // Show tables and custom styles inside tooltip
                sanitize: false,
                template: '<div class="help-tooltip tooltip" role="tooltip"><div class="help-tooltip arrow">' +
                    '</div><div class="help-tooltip tooltip-inner"></div></div>'
            });
            //hide it when clicking anywhere else
            jQuery('body').on('click', function (e) {
                jQuery('.helpTooltip').each(function () {
                    //the 'is' for buttons that trigger popups
                    //the 'has' for icons within a button that triggers a popup
                    if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0 && jQuery('.tooltip').has(e.target).length === 0) {
                        jQuery(this).tooltip('hide');
                    }
                });
            });
        });

        //Prevent clicking on help button activates input associated with label
        jQuery(document).on('tap click', 'label sup', function (event, data) {
            event.stopPropagation();
            event.preventDefault();
            return false;
        });

        if (this.props.geometricElementType && this.props.allCodes && this.props.user) {

            //Attributes order
            let listAttributeType = this.props.geometricElementType.listAttributeType.sort(sortGeometricElementTypeAttributeTypeComparator);

            this.initialValues = {
                file: [],
                description: "",
                internalComments: "",
                isPublic: false,
                isReviewed: false,
            };
            this.resetValues = {
                file: [],
                description: "",
                internalComments: "",
                isPublic: false,
                isReviewed: false,
            }

            this.schema = {};

            if (this.manageDescriptionFieldValue === MANAGE_GEOMETRIC_ELEMENT_DESCRIPTION_FIELD.mandatory) {

                this.schema["description"] = Yup.string().required(<FormattedMessage
                    id="project.app.Body.field.required"
                    values={{
                        field: <FormattedMessage id="project.elements.description" />,
                    }} />
                );

            }

            this.props.geometricElementType.listAttributeType.forEach(attributeType => {

                this.initialValues[attributeType.attributeType.code.code] = attributeType.dataType === "BOOLEAN_CHECK" ? false : "";
                this.resetValues[attributeType.attributeType.code.code] = attributeType.dataType === "BOOLEAN_CHECK" ? false : "";

                if (attributeType.mandatory) {

                    this.schema[attributeType.attributeType.code.code] = Yup.string().required(<FormattedMessage
                        id="project.app.Body.field.required"
                        values={{
                            field: <InternationalizationRender
                                locale={this.props.locale}
                                listInternationalization={this.props.allCodes}
                                value={attributeType.attributeType.code.code} />,
                        }} />);

                }

            });

            this.schema = Yup.object().shape({ ...this.schema });

            if (this.state.modifyFeature) {
                this.initialValues = {
                    file: [],
                    description: this.state.modifyFeature.description,
                };
                this.initialValues.internalComments = this.state.modifyFeature.internalComments ? this.state.modifyFeature.internalComments : "";
                this.initialValues.isPublic = this.state.modifyFeature.isPublic;
                this.initialValues.isReviewed = this.state.modifyFeature.isReviewed;
                let groupedValues = groupBy(this.state.modifyFeature.listAttributeValueDto, "elementTypeAttributeType.attributeType.code.code");
                let attributeNames = Object.keys(groupedValues);
                let values = {};
                attributeNames.forEach(attributeName => {
                    values[attributeName] = [];
                    groupedValues[attributeName].forEach(value => {
                        values[attributeName].push(value.value)
                    });
                });
                attributeNames.forEach(attributeName => {

                    let attributeType = groupedValues[attributeName][0].elementTypeAttributeType.attributeType;
                    if (attributeType.dataType !== "MULTISELECT") {

                        this.initialValues[attributeName] = values[attributeName][0];

                    } else {

                        if (attributeType.dataType === "BOOLEAN_CHECK") {

                            this.initialValues[attributeName] = values[attributeName] === "true";

                        } else {

                            this.initialValues[attributeName] = values[attributeName];

                        }

                    }
                });
            }

            FilePond.registerPlugin(FilePondPluginFileEncode);

            let modalTitle = () => {
                if (this.state.modifyFeature) {
                    return (
                        <FormattedMessage id="project.elements.modify"
                            values={{
                                element: getInternationalization(this.props.locale, this.props.geometricElementType.code, this.props.allCodes, this.props.activeLanguages)
                                    .toLowerCase()
                            }}
                        />
                    )
                } else {
                    return (
                        <FormattedMessage id="project.elements.new"
                            values={{
                                element: getInternationalization(this.props.locale, this.props.geometricElementType.code, this.props.allCodes, this.props.activeLanguages)
                                    .toLowerCase()
                            }}
                        />
                    )
                }
            }

            return (
                <Formik
                    initialValues={this.initialValues}
                    enableReinitialize={true}
                    validationSchema={this.schema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        var json = {
                            "id": this.state.modifyFeature ? this.state.modifyFeature.id : 0,
                            "description": values.description,
                            "geom": this.state.geom ? this.state.geom : this.state.modifyFeature.geom,
                            "internalComments": values.internalComments,
                            "isPublic": values.isPublic,
                            "isReviewed": values.isReviewed,
                            "geometricElementTypeDto": this.props.geometricElementType,
                            "listAttributeValueDto": []
                        };

                        if (this.state.modifyFeature) {
                            json.listFileToModifyDto = this.state.modifyFeature.listGeometricElementFileDto.map(geometricElementFileDto =>
                                this.fromGeometricElementFileDtoToFileToInsertJSON(geometricElementFileDto));
                        } else {
                            json.listFileToInsertDto = values.file;
                        }

                        const elementType = this.props.geometricElementType;

                        let contactDataNotNull = false;
                        listAttributeType.forEach(attributeType => {
                            let attributeValue = values[attributeType.attributeType.code.code];

                            if (attributeType.attributeType.dataType === "CONTACT_DATA" && !contactDataNotNull) {
                                if (attributeValue !== null &&
                                    attributeValue !== "") {
                                    contactDataNotNull = true;
                                }
                            }

                            Array.isArray(attributeValue)
                                && attributeValue.length > 0 ?
                                attributeValue.map(value =>
                                    json.listAttributeValueDto.push({
                                        "id": -1,
                                        "value": value,
                                        "elementTypeAttributeType": {
                                            "elementType": {
                                                "id": elementType.id,
                                                "geometryType": elementType.geometryType,
                                                "name": elementType.name
                                            },
                                            "attributeType": {
                                                "id": attributeType.attributeType.id,
                                                "description": attributeType.attributeType.description,
                                                "maxSize": attributeType.attributeType.maxSize,
                                                "dataType": attributeType.attributeType.dataType,
                                                "valueGroupCode": attributeType.attributeType.valueGroupCode,
                                                "code": attributeType.attributeType.code
                                            }
                                        }
                                    }))
                                :
                                json.listAttributeValueDto.push({
                                    "id": -1,
                                    "value": attributeType.attributeType.dataType === "BOOLEAN_CHECK" ? `${attributeValue === "true" || attributeValue ? "true" : "false"}`
                                        :
                                        attributeValue
                                            // eslint-disable-next-line
                                            && new String(attributeValue).length > 0 ?
                                            // eslint-disable-next-line
                                            new String(attributeValue)
                                            :
                                            null
                                    ,
                                    "elementTypeAttributeType": {
                                        "elementType": {
                                            "id": elementType.id,
                                            "geometryType": elementType.geometryType,
                                            "name": elementType.name
                                        },
                                        "attributeType": {
                                            "id": attributeType.attributeType.id,
                                            "description": attributeType.attributeType.description,
                                            "maxSize": attributeType.attributeType.maxSize,
                                            "dataType": attributeType.attributeType.dataType,
                                            "valueGroupCode": attributeType.attributeType.valueGroupCode,
                                            "code": attributeType.attributeType.code
                                        }
                                    }
                                })
                        });

                        let functionToExecute = null;
                        if (this.state.modifyFeature) {
                            functionToExecute = () => this.props.dispatch(actions.modifyGeometricElement(this.state.modifyFeature.id, json, () => {
                                this.setState({ modifyFeature: null });
                                this.props.clearDrawSource();
                                this.props.removeAllAddedInteractions(this.props.map);
                                this.props.resetModifyFeature();
                                this.props.closeAddGeometricElementForm(this.props.map);
                                this.props.refreshLayersCollection();
                                let resetValues = this.resetValues;
                                resetForm({ values: resetValues });
                                this.setState({ files: [], confirmationModalShow: false });
                                if (typeof this.props.submitModifyFromDetailsPage === 'function') {
                                    this.props.submitModifyFromDetailsPage();
                                }
                            },
                                errors => {
                                    this.setState({ backendErrors: errors });
                                }
                            ));

                        } else {
                            functionToExecute = () => this.props.dispatch(actions.addGeometricElement(json, () => {
                                this.props.clearDrawSource();
                                this.props.resetCreateElement();
                                this.props.closeAddGeometricElementForm(this.props.map);
                                this.props.removeAllAddedInteractions(this.props.map);
                                this.props.refreshLayersCollection();
                                jQuery('#addGeometricElementButton').click();
                                let resetValues = this.resetValues;
                                resetForm({ values: resetValues });
                                this.setState({ files: [], confirmationModalShow: false });
                            },
                                errors => {
                                    this.setState({ backendErrors: errors });
                                }
                            ));
                        }

                        if (this.enableGeometricElementConfirmationContactDataAlert &&
                            ((!values.isPublic && this.privateGeometricElementConfirmationContactDataAlert) ||
                                (values.isPublic && this.privateGeometricElementConfirmationContactDataAlert)) &&
                            contactDataNotNull) {
                            this.setState({
                                functionToExecute, confirmationModalShow: true
                            })
                        } else {
                            functionToExecute();
                        }

                        setSubmitting(false);
                    }}
                >
                    {({ setFieldValue, setFieldError, values, errors, resetForm }) =>
                        <div id="addGeometricElementContainer">
                            <Modal.Header>
                                <Modal.Title>
                                    {modalTitle()}
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Form className="needs-validation novalidate" id="addGeometricElementForm">
                                    {errors ? this.setState({ formikErrors: errors }) : ""}
                                    <Errors errors={this.state.backendErrors} onClose={() => this.setState({ backendErrors: null })} />
                                    {this.props.geometricElementType.listAttributeType.map(attributeType =>

                                        <GeometricElementTypeAttributesFields

                                            activeLanguages={this.props.activeLanguages}
                                            allCodes={this.props.allCodes}
                                            attributeType={attributeType}
                                            errors={errors}
                                            formatMessage={this.props.intl.formatMessage}
                                            locale={this.props.locale}
                                            setFieldError={setFieldError}
                                            setFieldValue={setFieldValue}
                                            values={values}
                                            key={attributeType.attributeType.id + "Component"}

                                        />

                                    )}

                                    <div className="form-group">
                                        <label htmlFor="description" className={`font-weight-bold ${this.manageDescriptionFieldValue === MANAGE_GEOMETRIC_ELEMENT_DESCRIPTION_FIELD.mandatory && 'required'}`}>
                                            <FormattedMessage id="project.elements.description" />
                                        </label>
                                        <Field as="textarea" name="description" className="form-control"
                                            id="description" rows="3" />
                                    </div>
                                    <ErrorMessage name="description" render={(msg) =>
                                        <div id='descriptionError' className="alert alert-danger alert-dismissible fade show" role="alert"
                                            hidden={typeof errors.description === "undefined"}
                                        >
                                            <button type="button" className="close" aria-label="Close"
                                                onClick={() => setFieldError("description", undefined)}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            {msg}
                                        </div>
                                    }
                                    />

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <Field as="input" type="checkbox" id="isPublic" name="isPublic" />
                                            </div>
                                        </div>
                                        <label htmlFor="isPublic" className="btn input-group-text">
                                            <FormattedMessage id="project.elements.isPublic" />
                                            &nbsp;
                                            <sup id="isPublicHelp"
                                                className="helpTooltip btn-link"
                                                style={{ cursor: "pointer" }}
                                                aria-hidden={true}
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                data-original-title={
                                                    this.props.intl.formatMessage({
                                                        id: "project.elements.isPublic.help"
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </sup>
                                        </label>
                                    </div>

                                    {this.props.user && this.props.user.userRoleDto.code === "ADMIN" ?
                                        <div>

                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <Field as="input" type="checkbox" id="isReviewed" name="isReviewed" />
                                                    </div>
                                                </div>
                                                <label htmlFor="isReviewed" className="btn input-group-text">
                                                    <FormattedMessage id="project.elements.isReviewed" />
                                                </label>
                                            </div>

                                            <div className="form-group">
                                                <label className="font-weight-bold" htmlFor="internalComments">
                                                    <FormattedMessage id="project.elements.internalComments" />
                                                </label>
                                                <Field as="textarea" name="internalComments" className="form-control" id="internalComments" />
                                            </div>
                                        </div>
                                        : ""}

                                    {!this.state.modifyFeature ?
                                        <div className="form-group">
                                            <label className="font-weight-bold" htmlFor="filePond">
                                                <FormattedMessage id="project.elements.files" />
                                            </label>
                                            <FilePond.FilePond
                                                ref={ref => this.pond = ref}
                                                id="filePond"
                                                allowMultiple={true}
                                                labelIdle={this.props.formatMessage({ id: 'project.common.filepond.labelIdle' })}
                                                labelTapToCancel={this.props.formatMessage({ id: 'project.common.filepond.labelTapCancel' })}
                                                labelFileLoading={this.props.formatMessage({ id: 'project.common.filepond.labelLoading' })}
                                                labelFileWaitingForSize={this.props.formatMessage({ id: 'project.common.filepond.labelWaitForSize' })}
                                                onupdatefiles={fileItems => {
                                                    this.setState({ files: fileItems });
                                                    setFieldValue("file", fileItems.map(fileItem => this.toFileToInsertJSON(fileItem)));
                                                }}
                                                files={this.state.files}
                                            />
                                        </div>
                                        : ""}
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <button id="addGeometricElementSubmit" type="submit" className="btn btn-primary"
                                    form="addGeometricElementForm"
                                >
                                    <FormattedMessage id="project.app.Body.accept" />
                                </button>
                                <Button id="addGeometricElementCancel" type="reset" variant="danger"
                                    onClick={() => {
                                        if (this.state.modifyFeature) {
                                            this.setState({ modifyFeature: null });
                                            this.props.clearDrawSource();
                                            this.props.removeAllAddedInteractions(this.props.map);
                                            this.props.closeAddGeometricElementForm(this.props.map);
                                            this.props.resetModifyFeature();
                                            let resetValues = this.resetValues;
                                            resetForm({ values: resetValues });
                                            this.setState({ files: [] });
                                            if (typeof this.props.cancelModifyFromDetailsPage === 'function') {
                                                this.props.cancelModifyFromDetailsPage();
                                            }
                                        } else {
                                            this.props.clearDrawSource();
                                            this.setState({ modifyFeature: null });
                                            this.props.removeAllAddedInteractions(this.props.map);
                                            this.props.resetCreateElement();
                                            this.props.closeAddGeometricElementForm(this.props.map);
                                            jQuery('#addGeometricElementButton').click();
                                            let resetValues = this.resetValues;
                                            resetForm({ values: resetValues });
                                            this.setState({ files: [] });
                                        }
                                    }}
                                >
                                    <FormattedMessage id="project.app.Body.cancel" />
                                </Button>
                            </Modal.Footer>

                            <AddModifyGeometricElementConfirmation
                                modalShow={this.state.confirmationModalShow}
                                functionToExecute={this.state.functionToExecute}
                                closeModalWindow={() => this.setState({ confirmationModalShow: false })}
                                geometricElementConfirmationContactDataAlert={values.isPublic ?
                                    this.publicGeometricElementConfirmationContactDataAlert :
                                    this.privateGeometricElementConfirmationContactDataAlert
                                }
                            />
                        </div>
                    }
                </Formik>
            );
        } else {
            return null;
        }
    }
}

export default withRouter(connect(mapStateToProps)(injectIntl(AddGeometricElement)));