import { combineReducers } from 'redux';

import * as actionTypes from './actionTypes';

const initialState = {
    error: null,
    loading: false,
    language: null,
    locale: null,
    activeLanguages: [],
    availableLanguages: [],
    applicationData: null
};

const error = (state = initialState.error, action) => {
    switch (action.type) {
        case actionTypes.ERROR:

            return action.error;

        default:
            return state;
    }
}

const loading = (state = initialState.loading, action) => {

    switch (action.type) {

        case actionTypes.LOADING:
            return true;

        case actionTypes.LOADED:
            return false;

        case actionTypes.ERROR:
            return false;

        default:
            return state;

    }

}

const language = (state = initialState.language, action) => {
    switch (action.type) {
        case actionTypes.APP_LANGUAGE:
            return action.language

        default:
            return state;
    }
}

const activeLanguages = (state = initialState.activeLanguages, action) => {
    switch (action.type) {
        case actionTypes.ACTIVE_LANGUAGES:
            return action.activeLanguages

        default:
            return state;
    }
}

const availableLanguages = (state = initialState.availableLanguages, action) => {
    switch (action.type) {
        case actionTypes.AVAILABLE_LANGUAGES:
            return action.availableLanguages

        default:
            return state;
    }
}

const applicationData = (state = initialState.applicationData, action) => {
    switch (action.type) {
        case actionTypes.APPLICATION_DATA:
            return action.applicationData

        default:
            return state;
    }
}

const locale = (state = initialState.locale, action) => {
    switch (action.type) {
        case actionTypes.APP_LOCALE:
            return action.locale

        default:
            return state;
    }
}


const reducer = combineReducers({
    error,
    loading,
    language,
    activeLanguages,
    availableLanguages,
    applicationData,
    locale
});

export default reducer;