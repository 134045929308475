import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as attachedFileSelectors from '../../attachedFile/selectors'
import InternationalizationRender, { PARAMETER_ELEMENT_TEXT_SINGULAR } from '../../app/components/InternationalizationRender';
import ExternalImage from '../../common/components/ExternalImage';
const WMS_VERSION = '1.3.0';
const ANY_GEOMETRY = "ANY_GEOMETRY";

const mapStateToProps = function (state) {

    return {
        allAttachedFiles: attachedFileSelectors.getTotalGeneralAttachedFiles(state)
    }
}

const LegendPreviewComponent = ({ modalShow, previewLegendComponent, hideModalWindow, ...props }) => {

    if (previewLegendComponent && modalShow) {

        let legendImages = [];
        let component = previewLegendComponent.component;

        if (component.legendType === "LEGEND_TYPE_GET_LEGEND_GRAPHIC") {
            if (previewLegendComponent.type === "geometricLayer") {
                let geometricLayerLayers = component.internalName.split(',')
                if (component.showAllLayersOnLegend) {
                    geometricLayerLayers.forEach(layer => {
                        let url = `${component.type.code === "WMS_DATABASE" || component.type.code === "WMS_SHAPE" ? `${process.env.REACT_APP_BACKEND_URL}/mapserver` : component.useProxy ? `${process.env.REACT_APP_BACKEND_URL}/layerserver` : component.serverUrl}?SERVICE=${component.type.code === "WMS_DATABASE" || component.type.code === "WMS_SHAPE" ? "WMS" : component.type.code}&VERSION=${component.version || WMS_VERSION}&REQUEST=GetLegendGraphic&FORMAT=image/png&layer=${layer}${component.useProxy ? `&GL_ID=${component.id}` : ''}${(component.type.code === "WMS_DATABASE" || component.type.code === "WMS_SHAPE") && component.refreshLegend ? `&RL=${component.refreshLegend}` : ''}`
                        let image = <ExternalImage className="d-block" src={url} alt="" />;
                        legendImages[legendImages.length] = image;
                    })
                } else {
                    let url = `${component.type.code === "WMS_DATABASE" || component.type.code === "WMS_SHAPE" ? `${process.env.REACT_APP_BACKEND_URL}/mapserver` : component.useProxy ? `${process.env.REACT_APP_BACKEND_URL}/layerserver` : component.serverUrl}?SERVICE=${component.type.code === "WMS_DATABASE" || component.type.code === "WMS_SHAPE" ? "WMS" : component.type.code}&VERSION=${component.version || WMS_VERSION}&REQUEST=GetLegendGraphic&FORMAT=image/png&layer=${geometricLayerLayers[0]}${component.useProxy ? `&GL_ID=${component.id}` : ''}${(component.type.code === "WMS_DATABASE" || component.type.code === "WMS_SHAPE") && component.refreshLegend ? `&RL=${component.refreshLegend}` : ''}`
                    let image = <ExternalImage className="d-block" src={url} alt="" />;
                    legendImages[legendImages.length] = image;
                }
            } else {
                if (previewLegendComponent.type === "geometricElementType") {
                    let geometryType = component.geometryType;
                    let styles = "STYLES=";
                    if (geometryType === ANY_GEOMETRY) {
                        styles += `${component.pointStyleName ? component.pointStyleName : ""},${component.lineStyleName ? component.lineStyleName : ""},${component.polygonStyleName ? component.polygonStyleName : ""}`;
                    } else {
                        if (geometryType.includes("POINT")) {
                            styles += `${component.pointStyleName ? component.pointStyleName : ""},`;
                        }
                        if (geometryType.includes("LINE")) {
                            styles += `${component.lineStyleName ? component.lineStyleName : ""},`;
                        }
                        if (geometryType.includes("POLYGON")) {
                            styles += `${component.polygonStyleName ? component.polygonStyleName : ""},`;
                        }
                    }
                    let url = `${process.env.REACT_APP_BACKEND_URL}/mapserver?SERVICE=WMS&VERSION=${WMS_VERSION}&REQUEST=GetLegendGraphic&FORMAT=image/png&layer=${component.code}&${styles}`
                    let image = <ExternalImage className="d-block" src={url} alt="" />;
                    legendImages[legendImages.length] = image;
                }
            }
        } else {
            if (component.legendType === "LEGEND_TYPE_URL") {
                legendImages[legendImages.length] = <ExternalImage className="d-block" src={component.legendTypeValue} alt="" />;
            }

            if (component.legendType === "LEGEND_TYPE_IMAGE") {
                //eslint-disable-next-line
                let generalAttachedFile = props.allAttachedFiles.filter(generalAttachedFile => generalAttachedFile.id == component.legendTypeValue);
                if (generalAttachedFile.length) {
                    let image = <ExternalImage className="d-block" src={generalAttachedFile[0].fileUrl} alt="" />
                    legendImages[legendImages.length] = image;
                } else {
                    legendImages[legendImages.length] = <ExternalImage className="d-block" src="" alt="" />;
                }
            }
        }

        return (
            <Modal show={modalShow} size="xs" onHide={hideModalWindow} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {previewLegendComponent.type === "geometricElementType" ?
                            <FormattedMessage
                                id="project.elements.legend.title"
                                values={{
                                    element: <>&laquo;<InternationalizationRender value={previewLegendComponent.component.code} />&raquo;</>,
                                    singular: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_SINGULAR} />
                                }}
                            />
                            :
                            <FormattedMessage
                                id="project.elements.geometricLayer.layer.legend.title"
                                values={{ layer: <>&laquo;<InternationalizationRender value={previewLegendComponent.component.code.code} />&raquo;</> }}
                            />
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {legendImages.map(image => image)}
                </Modal.Body>
            </Modal>
        );
    }

    return null;
}

export default connect(mapStateToProps)(LegendPreviewComponent);