import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as actions from '../actions';
import { FormattedMessage } from 'react-intl';
import { Errors } from '../../common'
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import jQuery from 'jquery'
import '../../globalstyles.css';
import PropTypes from 'prop-types';
import * as appSelectors from '../../app/selectors';
import { forceChangeLanguage } from '../../common/util';

const mapStateToProps = function (state) {

    return {
        activeLanguages: appSelectors.getActiveLanguages(state)
    }
}

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formikErrors: null
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.modalShow !== this.props.modalShow) {
            return true;
        }

        if (nextProps.createElement !== this.props.createElement) {
            return true;
        }

        if (
            (nextState.formikErrors !== this.state.formikErrors) ||
            (nextProps.backendErrors !== this.props.backendErrors)
        ) {
            return true;
        }
        return false;
    }

    componentDidUpdate() {

        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });

        if (this.state.formikErrors !== null || this.props.backendErrors !== null) {
            let container = jQuery('.modal-body');
            if (container.length > 0) {
                if (this.props.backendErrors) {
                    container.scrollTop(0);
                } else {
                    if (this.state.formikErrors) {
                        let field = jQuery('.alert:visible:first').prev();
                        if (field.length)
                            jQuery(container).animate({
                                scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                            });
                    }
                }
            }
        }
    }

    render() {

        let Schema = Yup.object().shape({
            login: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.users.login" />,
                }} />),
            password: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.users.password" />,
                }} />)
        });

        if (this.props.modalShow) {
            return (
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        login: "",
                        password: ""
                    }}
                    validationSchema={Schema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        this.setState({ backendErrors: null });
                        this.props.dispatch(actions.login(values.login, values.password,
                            (user) => {
                                if (this.props.activeLanguages.includes(user.user.locale.toUpperCase())) {
                                    forceChangeLanguage(user.user.locale);
                                } else {
                                    let splittedLanguage = this.props.activeLanguages[0].split("-");
                                    let validLanguage = splittedLanguage[0].toLowerCase() + "-" + splittedLanguage[1];
                                    forceChangeLanguage(user.user.locale);
                                    user.user.locale = validLanguage;
                                    this.props.dispatch(actions.changeLanguage(user.user));
                                }
                                this.props.hideModalWindow();
                                resetForm();

                            },
                            errors => {
                                this.props.setBackendErrors(errors);
                            },
                            () => {
                                this.props.history.push("/");
                                this.props.dispatch(actions.logout());
                            }
                        ));
                        setSubmitting(false);
                    }}
                >
                    {({ errors, setFieldError, resetForm }) =>
                        <Modal show={this.props.modalShow} onHide={this.props.hideModalWindow} scrollable={true}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <FormattedMessage id="project.app.Header.login" />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <Form className="needs-validation novalidate" id="loginForm">

                                    {errors ? this.setState({ formikErrors: errors }) : ""}

                                    <Errors errors={this.props.backendErrors} onClose={() => this.props.setBackendErrors(null)} />

                                    <div className="form-group">
                                        <label htmlFor="login" className="font-weight-bold required">
                                            <FormattedMessage id="project.users.login" />
                                        </label>
                                        <Field id="login" name="login" type="text" className="form-control" />
                                    </div>
                                    <ErrorMessage name="login" render={(msg) =>
                                        <div id="loginError" className="alert alert-danger alert-dismissiImplementación da interface de usuarioble fade show" role="alert"
                                            hidden={typeof errors.login === "undefined"}
                                        >
                                            <button type="button" className="close" aria-label="Close"
                                                onClick={() => setFieldError("login", undefined)}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            {msg}
                                        </div>
                                    }
                                    />

                                    <div className="form-group">
                                        <label htmlFor="password" className="font-weight-bold required">
                                            <FormattedMessage id="project.users.password" />
                                        </label>
                                        <Field id="password" name="password" type="password" className="form-control" />
                                    </div>

                                    <ErrorMessage name="password" render={(msg) =>
                                        <div id="passworError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                            hidden={typeof errors.password === "undefined"}
                                        >
                                            <button type="button" className="close" aria-label="Close"
                                                onClick={() => setFieldError("password", undefined)}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            {msg}
                                        </div>
                                    }
                                    />
                                </Form>

                                <div className="dropdown-divider"></div>

                                <button
                                    id="signUpButton"
                                    className='btn btn-link'
                                    onClick={() => {
                                        this.props.dispatch(actions.findRoleByCode('USER'));
                                        this.props.handleOpenSignUpModalWindow();
                                        resetForm()
                                    }}
                                >
                                    <FormattedMessage id="project.app.Header.signUp" />
                                </button>

                            </Modal.Body>
                            <Modal.Footer>
                                <button id="loginSubmit" type="submit" className="btn btn-primary" form="loginForm">
                                    <FormattedMessage id="project.app.Header.login" />
                                </button>
                                <button id="loginCancel" type="button" className="btn btn-danger" onClick={() => {
                                    this.props.hideModalWindow();
                                    resetForm()
                                }}
                                >
                                    <FormattedMessage id="project.app.Body.cancel" />
                                </button>
                            </Modal.Footer>
                        </Modal>
                    }
                </Formik>
            );
        }

        return null;
    }
}

Login.propTypes = {
    modalShow: PropTypes.bool.isRequired,
    createElement: PropTypes.object,
    backendErrors: PropTypes.object,
    setBackendErrors: PropTypes.func.isRequired,
    hideModalWindow: PropTypes.func.isRequired,
    handleOpenSignUpModalWindow: PropTypes.func.isRequired
}

export default withRouter(connect(mapStateToProps)(Login));